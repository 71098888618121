var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "16.25rem" } })
        : _c(
            "div",
            { staticClass: "monitoring-trademark-card" },
            [
              _c("monitoring-instructional-accordion", {
                attrs: {
                  id: "trademarks-collapse",
                  header: "Trademark Monitoring",
                  "instructional-text": _vm.instructionalContent,
                },
              }),
              _vm.trademarks?.length
                ? _c(
                    "div",
                    [
                      _c("b-table", {
                        attrs: { items: _vm.trademarks, fields: _vm.fields },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(active)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "active-button" },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          name: "check-button",
                                          switch: "",
                                          size: "lg",
                                          disabled:
                                            !_vm.isEditMode ||
                                            _vm.currentEditItem?.id !==
                                              data.item.id,
                                        },
                                        model: {
                                          value: data.item.active,
                                          callback: function ($$v) {
                                            _vm.$set(data.item, "active", $$v)
                                          },
                                          expression: "data.item.active",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "head(value)",
                              fn: function () {
                                return [
                                  _vm._v("\n          Mark Literal\n        "),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(value)",
                              fn: function (data) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      disabled:
                                        !_vm.isEditMode ||
                                        _vm.currentEditItem?.id !==
                                          data.item.id,
                                    },
                                    model: {
                                      value: data.item.value,
                                      callback: function ($$v) {
                                        _vm.$set(data.item, "value", $$v)
                                      },
                                      expression: "data.item.value",
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "cell(international_class)",
                              fn: function (data) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      disabled:
                                        !_vm.isEditMode ||
                                        _vm.currentEditItem?.id !==
                                          data.item.id,
                                      state: _vm.tableValidation[data.item.id]
                                        ?.icInvalid
                                        ? false
                                        : null,
                                      "aria-describedby":
                                        "new-international-class-feedback-" +
                                        data.item.id,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleTableIcInput(data.item)
                                      },
                                    },
                                    model: {
                                      value: data.item.international_class,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          data.item,
                                          "international_class",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.item.international_class",
                                    },
                                  }),
                                  _vm.tableValidation[data.item.id]?.icInvalid
                                    ? _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: {
                                            id:
                                              "new-international-class-feedback-" +
                                              data.item.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            Must be 3 digits\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "head(global_id)",
                              fn: function () {
                                return [
                                  _vm._v("\n          Serial Number\n        "),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(global_id)",
                              fn: function (data) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      disabled:
                                        !_vm.isEditMode ||
                                        _vm.currentEditItem?.id !==
                                          data.item.id,
                                      state: _vm.tableValidation[data.item.id]
                                        ?.globalIdInvalid
                                        ? false
                                        : null,
                                      "aria-describedby":
                                        "new-serial-number-feedback-" +
                                        data.item.id,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleTableGlobalIdInput(
                                          data.item
                                        )
                                      },
                                    },
                                    model: {
                                      value: data.item.global_id,
                                      callback: function ($$v) {
                                        _vm.$set(data.item, "global_id", $$v)
                                      },
                                      expression: "data.item.global_id",
                                    },
                                  }),
                                  _vm.tableValidation[data.item.id]
                                    ?.globalIdInvalid
                                    ? _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: {
                                            id:
                                              "new-serial-number-feedback-" +
                                              data.item.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            Must be 8 digits\n          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "manage-buttons" },
                                    [
                                      _vm.isEditMode &&
                                      _vm.currentEditItem?.id === data.item.id
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "save-button",
                                              attrs: {
                                                "aria-label": "Save button",
                                                variant: "primary",
                                                size: "sm",
                                                disabled: !_vm.canSave(
                                                  data.item
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveChanges(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Save\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isEditMode ||
                                      _vm.currentEditItem?.id !== data.item.id
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "edit-button",
                                              attrs: {
                                                "aria-label": "Edit button",
                                                variant: "primary",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItem(data.item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Edit\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEditMode &&
                                      _vm.currentEditItem?.id === data.item.id
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "cancel-button",
                                              attrs: {
                                                "aria-label": "Cancel button",
                                                variant: "secondary",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelEdit(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Cancel\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isEditMode ||
                                      _vm.currentEditItem?.id !== data.item.id
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "delete-button",
                                              attrs: {
                                                "aria-label": "Delete button",
                                                variant: "danger",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleModal(
                                                    "confirmDelete",
                                                    { itemToDelete: data.item }
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Remove\n            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3466183202
                        ),
                      }),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "add-trademark-buttons" },
                          [
                            _vm.formattedOwnedData?.length
                              ? _c(
                                  "div",
                                  { staticClass: "add-owned-item-button" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleModal(
                                              "addOwnedItem",
                                              _vm.refreshNewItem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Add Trademark from your account\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleModal(
                                      "addAction",
                                      _vm.refreshNewItem
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            Add external Trademark\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "monitoring-no-identity",
                    { attrs: { "identity-type": "trademarks" } },
                    [
                      _c(
                        "div",
                        { staticClass: "add-trademark-buttons" },
                        [
                          _vm.formattedOwnedData?.length
                            ? _c(
                                "div",
                                { staticClass: "add-owned-item-button" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleModal(
                                            "addOwnedItem",
                                            _vm.refreshNewItem
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Add Trademark from your account\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleModal(
                                    "addAction",
                                    _vm.refreshNewItem
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          Add external Trademark\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", title: "Add Trademark from Account" },
          model: {
            value: _vm.modalVisibility.addOwnedItem,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "addOwnedItem", $$v)
            },
            expression: "modalVisibility.addOwnedItem",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.formattedOwnedData },
                    model: {
                      value: _vm.newItem,
                      callback: function ($$v) {
                        _vm.newItem = $$v
                      },
                      expression: "newItem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    "aria-label": "Add button",
                  },
                },
                [_vm._v("Add")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", "aria-label": "Cancel button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleModal("addOwnedItem", { newItem: {} })
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { "hide-footer": "", title: "Add Trademark(s)" },
          model: {
            value: _vm.modalVisibility.addAction,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "addAction", $$v)
            },
            expression: "modalVisibility.addAction",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    staticClass: "mb-2",
                    attrs: {
                      placeholder: "Existing Trademark Serial Number",
                      state: _vm.showGlobalIdValidation ? false : null,
                      disabled:
                        !!_vm.newItem.international_class ||
                        !!_vm.newItem.value,
                      "aria-describedby": "external-serial-number-feedback",
                    },
                    on: { input: _vm.handleGlobalIdInput },
                    model: {
                      value: _vm.newItem.global_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "global_id", $$v)
                      },
                      expression: "newItem.global_id",
                    },
                  }),
                  _vm.showGlobalIdValidation
                    ? _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "external-serial-number-feedback" } },
                        [_vm._v("\n          Must be 8 digits\n        ")]
                      )
                    : _vm._e(),
                  _vm._v("\n        OR\n        "),
                  _c("b-form-input", {
                    staticClass: "mb-2",
                    attrs: {
                      disabled: !!_vm.newItem.global_id,
                      placeholder: "Trademark Mark Literal",
                    },
                    model: {
                      value: _vm.newItem.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "value", $$v)
                      },
                      expression: "newItem.value",
                    },
                  }),
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.showIcValidation ? false : null,
                      disabled: !!_vm.newItem.global_id,
                      placeholder: "International Class",
                      "aria-describedby":
                        "external-international-class-feedback",
                    },
                    on: { input: _vm.handleIcInput },
                    model: {
                      value: _vm.newItem.international_class,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "international_class", $$v)
                      },
                      expression: "newItem.international_class",
                    },
                  }),
                  _vm.showIcValidation
                    ? _c(
                        "b-form-invalid-feedback",
                        {
                          attrs: {
                            id: "external-international-class-feedback",
                          },
                        },
                        [_vm._v("\n          Must be 3 digits\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    "aria-label": "Add button",
                    disabled: !_vm.canAdd,
                  },
                  on: { click: _vm.validateInputs },
                },
                [_vm._v("\n        Add\n      ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", "aria-label": "Cancel button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleModal("addAction", { saveItem: {} })
                    },
                  },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            title: "Are you sure you want to delete?",
          },
          model: {
            value: _vm.modalVisibility.confirmDelete,
            callback: function ($$v) {
              _vm.$set(_vm.modalVisibility, "confirmDelete", $$v)
            },
            expression: "modalVisibility.confirmDelete",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                variant: "secondary",
                block: "",
                "aria-label": "Cancel button",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleModal("confirmDelete", { itemToDelete: {} })
                },
              },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                variant: "danger",
                block: "",
                "aria-label": "Confirm button",
              },
              on: { click: _vm.deleteItem },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }